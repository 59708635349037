(<template>
  <section :class="{'in-progress': progressActive}"
           class="ticket-system is-progress-bar">
    <page-data-section :title="sectionTitle"
                       :description="description"
                       class="ticket-system__heading">
      <button class="sk-btn sk-btn--default ticket-system__btn"
              @click="openAddTicketModal">{{ $gettext('Make a ticket') }}</button>
      <div v-if="showTicketStatistics"
           class="ticket-system__statistics-link-wrapper">
        <router-link :to="{ name: 'BuyerTicketsStatistics', query: { page: 1, items: 10 } }"
                     class="sk-link">
          {{ $gettext('View statistics') }}
        </router-link>
      </div>
    </page-data-section>
    <section class="ticket-system__table">
      <page-data-tabs :tabs="tabs"
                      :active-tab-id="activeTab"
                      @tabchanged="setActiveTab" />
      <page-data-table :data="tickets"
                       :cells="tableCells"
                       :row-links="true"
                       class="ticket-system__ticket-table" />
      <sk-pagination v-if="pageTotal && pageTotal > 1"
                     :current="+currentPage"
                     :total="+pageTotal"
                     class="ticket-system__pagination"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </section>
  </section>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import helpers from '@/helpers';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import PageDataTable from '@/components/shared_components/page_data/PageDataTable';
  import PageDataTabs from '@/components/shared_components/page_data/PageDataTabs';

  export default {
    asyncData({store, route}) {
      const {view, page} = route.query;
      const params = {page, items: 10};
      let promisesArr = '';

      if (view === 'opened') params.status = ['pending', 'unresolved'];
      if (view === 'finished') params.status = 'resolved';
      if (view === 'hidden') params.hidden = true;
      if (!store.state.TicketSystemStore.tickets.tickets) {
        promisesArr = [...promisesArr, store.dispatch('TicketSystemStore/getTickets', params)];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'page-data-section': PageDataSection,
      'page-data-table': PageDataTable,
      'page-data-tabs': PageDataTabs
    },
    data() {
      return {
        progressActive: false,
        currentPage: +this.$route.query.page || 1,
        activeTab: this.$route.query.view || ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['enterpriseMemberJobsScope', 'userEmail', 'showTicketStatistics']),
      ...mapGetters('TicketSystemStore', ['ticketsList', 'pageTotal']),
      sectionTitle() { return this.$gettext('Tickets'); },
      description() {
        const template = this.$gettext('Create a new one, or respond to a ticket from %{domain}');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      tickets() {
        return this.ticketsList.map((ticket = {}) => {
          return {
            ...ticket,
            rowLink: this.$makeRoute({name: 'BuyerOneTicket', params: {id: ticket.id}})
          };
        });
      },
      tabsList() {
        return [{
          id: 'opened',
          name: this.$gettext('Opened'),
          link: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}})
        }, {
          id: 'finished',
          name: this.$pgettext('tickets', 'Finished'),
          link: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'finished', page: 1}})
        }, {
          id: 'hidden',
          condition: this.$isGodmode(),
          name: this.$gettext('Hidden'),
          link: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'hidden', page: 1}}),
          classes: ['page-data-tabs__tab--hidden']
        }];
      },
      tabs() {
        return this.tabsList.filter((tab) => { return tab.condition !== undefined ? tab.condition : true; });
      },
      tableCells() {
        return [
          {
            name: this.$gettext('Nr.'),
            dataField: 'id',
            type: 'itemNumber',
            fieldTemplate: this.$gettext('#ST-%{id}')
          },
          {
            name: this.$gettext('Status'),
            dataField: 'status',
            type: 'status',
            statusList: [
              {id: 'pending', text: helpers.ticketSystemInfo.ticketStatus(this, 'pending')},
              {id: 'resolved', text: helpers.ticketSystemInfo.ticketStatus(this, 'resolved')},
              {id: 'unresolved', text: helpers.ticketSystemInfo.ticketStatus(this, 'unresolved')},
            ]
          },
          {
            name: this.$gettext('Issue'),
            dataField: 'category',
            type: 'status',
            statusList: [
              {id: 'assignment', text: helpers.ticketSystemInfo.ticketCategory(this, 'assignment')},
              {id: 'finance', text: helpers.ticketSystemInfo.ticketCategory(this, 'finance')},
              {id: 'other', text: helpers.ticketSystemInfo.ticketCategory(this, 'other')},
            ]
          },
          {
            name: this.$gettext('Date of creation'),
            dataField: 'createdAt',
            type: 'date',
            dateFormat: 'DD/MM/YYYY HH:mm'
          }
        ];
      }
    },
    watch: {
      enterpriseMemberJobsScope() {
        this.clearStore();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    methods: {
      ...mapActions('TicketSystemStore', ['createTicket', 'getTickets', 'addParticipant']),
      ...mapMutations('TicketSystemStore', ['clearStore']),
      setActiveTab(tab) {
        this.activeTab = tab.id;
        this.currentPage = 1;
        this.clearStore();
        this.$router.push(tab.link);
      },
      submitFilters() {
        const queryParams = {view: this.$route.query.view};

        if (this.currentPage) { queryParams.page = this.currentPage; }
        this.clearStore();
        this.$router.push(this.$makeRoute({name: 'BuyerTicketSystem', query: queryParams}));
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.submitFilters();
      },
      toNewPage(page) {
        if (this.currentPage !== page) {
          this.currentPage = +page;
          this.submitFilters();
        }
      },
      toNextPage() {
        this.currentPage += 1;
        this.submitFilters();
      },
      openAddTicketModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-ticket-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Submit a new ticket'),
            context: this
          }
        });
      },
      addTicket() {
        this.progressActive = true;
        this.createTicket().then((data) => {
          const {view, page} = this.$route.query;
          const params = {page, items: 10};

          if (view === 'opened') params.status = ['pending', 'unresolved'];
          if (view === 'finished') params.status = 'resolved';
          if (view === 'hidden') params.hidden = true;
          this.getTickets(params).then(() => {
            this.openAddTicketSuccessModal(data.ticket);
            this.progressActive = false;
          });
        }).catch(() => {
          this.progressActive = false;
        });
      },
      openAddTicketSuccessModal(ticket) {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-ticket-success-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Thank you for submitting a ticket'),
            ticket
          }
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      this.clearStore();
      next();
    }
  };
</script>

<style src="@assets/css/page-data-section.css"></style>

<style>
  .ticket-system .page-data-table__row-wrapper:hover {
    opacity: 0.75;
    cursor: pointer;
  }

  .ticket-system .page-data-table__row-wrapper:focus {
    outline: auto;
  }

  .ticket-system .page-data-table__cell--unresolved {
    color: #008489;
  }

  .ticket-system .page-data-table__cell--resolved {
    color: #34c0cc;
  }

  .ticket-system .page-data-table__cell--pending {
    color: var(--color-secondary);
  }

  .ticket-system .page-data-tabs__tab--hidden {
    position: relative;
  }

  .ticket-system .page-data-tabs__tab--hidden::after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 11px;
    height: 11px;
    background-image: url(~@assets/imgs/undefined_imgs/blocked_icon_orange.svg);
    background-size: contain;
  }
</style>

<style scoped>
  .ticket-system {
    width: 100%;
    max-width: 737px;
    margin: 40px auto;
  }

  .ticket-system__heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .ticket-system__statistics-link-wrapper {
    width: 100%;
  }

  .ticket-system__table {
    margin-top: 20px;
  }

  .ticket-system__ticket-table {
    background-color: #fff;
  }

  .ticket-system__btn {
    overflow: initial;
    width: auto;
    padding: 0 15px;
  }

  .ticket-system__pagination {
    margin-top: 20px;
  }
</style>
